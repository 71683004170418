import React, { useState } from 'react';
import { Box, Typography, TextField, Button, Paper, useTheme } from '@mui/material';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const theme = useTheme();
    const { mode } = theme.palette;

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${process.env.REACT_APP_SERVICE_URL}/api/auth/forgot-password`, { email });
            toast.success(response.data.message || 'Reset link sent successfully!', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } catch (error) {
            toast.error(error.response?.data?.message || 'Failed to send reset link.', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh', backgroundColor: '#1a223f', padding: 3 }}>
            <Paper elevation={3} sx={{ padding: 4, width: '400px' }}>
                <Typography variant="h5" gutterBottom sx={{ color: mode === 'light' ? '#000' : '#fff' }}>
                    Forgot Password
                </Typography>
                <form onSubmit={handleSubmit}>
                    <TextField
                        label="Email"
                        variant="outlined"
                        type="email"
                        fullWidth
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        sx={{ mb: 2 }}
                    />
                    <Button variant="contained" color="primary" type="submit" fullWidth>
                        Send Reset Link
                    </Button>
                </form>
            </Paper>
            <ToastContainer />
        </Box>
    );
};

export default ForgotPassword;
