import React, { lazy } from 'react';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import PrivateRoute from './PrivateRoute';
import SubscriptionData from 'views/Pricing/paddle';
import Setting from 'views/Setting';
import RemoveBackground from 'views/Removebackground';
import AudioProject from 'views/AudioProject';
import AudProjectDetailsPage from 'views/AudioProject/projectDetailsPage';
import ImageDescription from 'views/ImageDescription';
import VideoProjectPage from 'views/CreateVideo/project';
import TextToVideo from 'views/CreateVideo';
import VideoProjectPages from 'views/CreateVideo/projectById';
import RedeemCode from 'views/redeemCode/redeemCode';

const DashboardDefault = Loadable(lazy(() => import('views/Dashboard/index')));
const CreateImage = Loadable(lazy(() => import('views/CreateImage/index')));
const Midjounery = Loadable(lazy(() => import('views/MidJounery/index')));
const UpscaleImage = Loadable(lazy(() => import('views/Upscale-Image/index')));
const StoryGenerator = Loadable(lazy(() => import('views/Story-generator/index')));
const RefinePrompt = Loadable(lazy(() => import('views/Prompt-generator/index')));
const Project = Loadable(lazy(() => import('views/Project/index')));
const ProjectDetail = Loadable(lazy(() => import('views/Project/ProjectDetailsPage')));
const TextToAudio = Loadable(lazy(() => import('views/TextToAudio/index')));
const Team = Loadable(lazy(() => import('views/Team/index')));
const PricingPage = Loadable(lazy(() => import('views/Pricing/index')));

const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/',
      element: <PrivateRoute element={<DashboardDefault />} />
    },
    {
      path: 'text-to-img',
      element: <PrivateRoute element={<CreateImage />} />
    },
    {
      path: 'mid-jounery',
      element: <PrivateRoute element={<Midjounery />} />
    },
    {
      path: 'pricing-data',
      element: <PrivateRoute element={<PricingPage />} />
    },
    {
      path: 'story-generator',
      element: <PrivateRoute element={<StoryGenerator />} />
    },
    {
      path: 'prompt-generator',
      element: <PrivateRoute element={<RefinePrompt />} />
    },
    {
      path: 'project',
      element: <PrivateRoute element={<Project />} />
    },
    {
      path: 'project/:id',
      element: <PrivateRoute element={<ProjectDetail />} />
    },
    {
      path: 'text-to-audio',
      element: <PrivateRoute element={<TextToAudio />} />
    },
    {
      path: 'audio-project',
      element: <PrivateRoute element={<AudioProject />} />
    },
    {
      path: 'audio-project/:id',
      element: <PrivateRoute element={<AudProjectDetailsPage />} />
    },
    {
      path: 'team',
      element: <PrivateRoute element={<Team />} />
    },
    {
      path: 'pricing',
      element: <PrivateRoute element={<SubscriptionData />} />
    },
    {
      path: 'setting',
      element: <PrivateRoute element={<Setting />} />
    },
    {
      path: 'upscale-image',
      element: <PrivateRoute element={<UpscaleImage />} />
    },
    {
      path: 'background-remover',
      element: <PrivateRoute element={<RemoveBackground />} />
    },
    {
      path: 'image-to-description',
      element: <PrivateRoute element={<ImageDescription />} />
    },
    {
      path: 'text-to-video',
      element: <PrivateRoute element={<TextToVideo />} />
    },
    {
      path: 'video-project',
      element: <PrivateRoute element={<VideoProjectPage />} />
    },
    {
      path: 'video-project/:id',
      element: <PrivateRoute element={<VideoProjectPages />} />
    },
    {
      path: 'redeem-code',
      element: <PrivateRoute element={<RedeemCode />} />
    },
  ]
};

export default MainRoutes;
