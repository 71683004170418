import React, { useEffect } from 'react';

const PayPalSubscriptionForm = () => {

    useEffect(() => {
        // Load the PayPal SDK script dynamically for subscription button
        const script1 = document.createElement('script');
        script1.src = 'https://www.paypal.com/sdk/js?client-id=AbRLlHH8bL6MdlW1bCt9rz96P-nWw6YrSEGyZkoGvsAJnj2OAMUroSTpOR4Uhi0nNMQ4SF_nnljDzDjl&vault=true&intent=subscription';
        script1.async = true;
        script1.onload = () => {
            // Render PayPal subscription buttons
            window.paypal.Buttons({
                style: {
                    shape: 'pill',
                    color: 'blue',
                    layout: 'vertical',
                    label: 'subscribe'
                },
                createSubscription: function (data, actions) {
                    return actions.subscription.create({
                        /* Creates the subscription */
                        plan_id: 'P-8SD58849JF360221AM3MT7AQ'
                    });
                },
                onApprove: function (data, actions) {
                    alert(`Subscription successful! Subscription ID: ${data.subscriptionID}`);
                }
            }).render('#paypal-button-container-subscription'); // Renders the PayPal subscription button
        };
        document.body.appendChild(script1);

        // Load the PayPal SDK script for hosted buttons
        const script2 = document.createElement('script');
        script2.src = 'https://www.paypal.com/sdk/js?client-id=BAAKwTUqP_gNTUsh_ATD2tj4zKPzxSZavY2Jt3Lu7MXMv3KD0uxYVEqIKyiDv2_aWpAT2HpYnOIbfX3ZrU&components=hosted-buttons&disable-funding=venmo&currency=USD';
        script2.async = true;
        script2.onload = () => {
            // Render the PayPal hosted button
            window.paypal.HostedButtons({
                hostedButtonId: "GMQHQTKGS9ZTS",
            }).render("#paypal-container-hosted");
        };
        document.body.appendChild(script2);

        // Clean up the scripts when the component unmounts
        return () => {
            document.body.removeChild(script1);
            document.body.removeChild(script2);
        };
    }, []);

    return (
        <div>
            <h2>Subscribe with PayPal</h2>
            <div id="paypal-button-container-subscription"></div> {/* PayPal subscription button */}

            <h2>Pay with Hosted Button</h2>
            <div id="paypal-container-hosted"></div> {/* PayPal hosted button */}
        </div>
    );
};

export default PayPalSubscriptionForm;
