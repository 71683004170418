import React, { useState, useEffect, useRef, useContext } from 'react';
import { Grid, Link, Typography, Button, IconButton, CircularProgress, Box } from '@mui/material';
import UploadIcon from '@mui/icons-material/Upload';
import SubCard from 'ui-component/cards/SubCard';
import MainCard from 'ui-component/cards/MainCard';
import { gridSpacing } from 'store/constant';
import axios from 'axios';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import AppContext from 'context/AppContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DownloadIcon from '@mui/icons-material/Download';


const serving_url = process.env.REACT_APP_SERVICE_URL;

const ImageDescription = () => {
    const [file, setFile] = useState(null);
    const [previewUrl, setPreviewUrl] = useState('');
    const [croppedUrl, setCroppedUrl] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [uploadUrl, setUploadUrl] = useState('');
    const [imageDescription, setImageDesc] = useState('');
    const cropperRef = useRef(null);
    const context = useContext(AppContext);
    const { state } = context;
    const { credits, setCredits, userData, remainingCredits, selectedProject } = state;


    useEffect(() => {
        if (file) {
            const objectUrl = URL.createObjectURL(file);
            setPreviewUrl(objectUrl);

            return () => URL.revokeObjectURL(objectUrl);
        } else {
            setPreviewUrl('');
        }
    }, [file]);

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            // Validate file type if necessary
            if (!selectedFile.type.startsWith('image/')) {
                setError('Please select a valid image file');
                setFile(null);
                return;
            }
            setFile(selectedFile);
            setError('');
        }
    };

    const handleCrop = () => {
        const cropper = cropperRef.current?.cropper;
        if (cropper) {
            setCroppedUrl(cropper.getCroppedCanvas().toDataURL())
        }
    };

    const uploadImgOns3 = (image) => {
        setUploadUrl('');
        fetch(image)
            .then(res => res.blob())
            .then((res) => {
                uploadFile(res)
            });
    }


    const uploadFile = async (croppedUrl) => {
        const file = croppedUrl;

        if (file) {
            setLoading(true);
            const SERVING_URL = process.env.REACT_APP_SERVICE_URL;
            const url = `${SERVING_URL}/api/uploading`;

            const formData = new FormData();
            formData.append("file", file);
            formData.append("fileType", file.type);

            fetch(url, {
                method: 'POST',
                body: formData
            })
                .then(res => res.json())
                .then(response => {
                    if (response.key) {
                        const src = `https://syraai1.s3.amazonaws.com/${response.key}`
                        ImageDesc(src)
                    } else {
                        setError('Failed to get url');
                        setLoading(false)
                    }
                })
        }
    }

    const ImageDesc = async (src) => {
        if (remainingCredits <= 0) {
            setLoading(false)
            return false;
        }
        setImageDesc('');
        setError('');

        try {
            const response = await axios.post(`${serving_url}/api/image-to-description`, {
                image: src,
                uid: userData?.email,
                projectId: selectedProject._id
            });

            if (response?.data?.description) {
                setImageDesc(response.data.description)
                toast.success('text successfully generated!');
                setLoading(false);
            } else {
                setLoading(false);
                setError('Failed to generate text');
                toast.error('Failed to generate text');
            }
        } catch (error) {
            setLoading(false);
            setError('An error occurred while generating the text');
            toast.error('An error occurred while generating the text');
        }
    };




    return (
        <MainCard title="Image to text">
            <Grid container spacing={gridSpacing}>
                <Grid item xs={12}>
                    <SubCard title="Upload Image">
                        <Grid container spacing={2} alignItems="center">
                            <Grid item>
                                <input
                                    accept="image/*"
                                    style={{ display: 'none' }}
                                    id="upload-file"
                                    type="file"
                                    onChange={handleFileChange}
                                />
                                <label htmlFor="upload-file">
                                    <IconButton color="primary" aria-label="upload picture" component="span">
                                        <UploadIcon />
                                    </IconButton>
                                </label>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => { uploadImgOns3(croppedUrl) }}
                                    disabled={loading || !croppedUrl || remainingCredits <= 0}
                                >
                                    {loading ? <CircularProgress size={24} /> : 'Generate'}
                                </Button>
                            </Grid>
                        </Grid>

                        <Grid item sx={{ display: 'flex', justifyContent: 'space-between', m: 4 }}>
                            {previewUrl && (
                                <Box sx={{ mt: 2 }}>
                                    <Typography variant="body1">Image Preview:</Typography>
                                    <Cropper
                                        src={previewUrl}
                                        style={{ height: 400, width: '60%' }}
                                        initialAspectRatio={1}
                                        guides={false}
                                        ref={cropperRef}
                                        crop={handleCrop}
                                    />
                                </Box>
                            )}

                            {imageDescription ? (
                                <Box sx={{ maxWidth: '500px', width: '100%', mt: 4, textAlign: 'center' }}>
                                    <Typography
                                        variant="h6"
                                        sx={{ backgroundColor: '#f0f0f0', padding: 2, borderRadius: '8px', boxShadow: 1 }}
                                    >
                                        {imageDescription}
                                    </Typography>
                                </Box>
                            ) : previewUrl ?
                                <Box sx={{ mt: '38px', display: 'flex', justifyContent: 'center', textAlign: 'center', border: '1px dashed #dfdfdf', width: '400px', height: '400px' }}>
                                    {loading && <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}><CircularProgress size={24} /></Box>}
                                </Box> : ''
                            }
                        </Grid>

                        {error && (
                            <Typography color="error" variant="body2" sx={{ mt: 2 }}>
                                {error}
                            </Typography>
                        )}

                    </SubCard>
                </Grid>
            </Grid>
            <ToastContainer />
        </MainCard>
    );
};

export default ImageDescription;
