import { useEffect, useState } from 'react';
import AppContext from './AppContext';
import { jwtDecode } from 'jwt-decode';
import axios from 'axios';

const AppProvider = ({ children }) => {
  const [apiLoading, setApiLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [selectedProject, setSelectedProject] = useState('');
  const [selectedAudProject, setSelectedAudProject] = useState('');
  const [selectedVidProject, setSelectedVidProject] = useState('');
  const [userData, setUserData] = useState('');
  const [credits, setCredits] = useState(0)
  const [remainingCredits, setRemainingCredits] = useState(0)
  const [openLogin, setOpenLogin] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
    setApiLoading(false);
  }, []);




  const serving_url = process.env.REACT_APP_SERVICE_URL
  const token = localStorage.getItem('token');

  useEffect(() => {
    console.log(token, "token")
    if (token) {
      console.log(token, "token")
      getUser()
    }
  }, [token])

  const getUser = async () => {
    const token = localStorage.getItem('token');
    const decoded = jwtDecode(token);
    setApiLoading(true);
    try {
      const response = await axios.post(`${serving_url}/api/auth/userinfo`, {
        email: decoded?.email
      });

      setUserData(response.data.user);
      console.log(response.data.user, "response.data.user")
      let UserEmail = response.data.user.email

      fetchImgProjects(UserEmail);
      fetchAudioProjects(UserEmail);
      fetchVideoProjects(UserEmail);

      setCredits(response.data.user.imageUsed)
    } catch (error) {
    } finally {
      setApiLoading(false);
    }
  };

  const fetchImgProjects = async (UserEmail) => {
    try {
      const response = await axios.get(`${serving_url}/api/get-img-projects`, {
        params: { email: UserEmail }
      });

      if (response.data.projects && response.data.projects.length > 0) {
        setSelectedProject(response.data.projects[0]);
      }
    } catch (error) {
      console.error('Error fetching projects:', error);
    }
  };

  const fetchAudioProjects = async (UserEmail) => {
    try {
      const response = await axios.get(`${serving_url}/api/get-audio-projects`, {
        params: { email: UserEmail }
      });

      if (response.data.projects && response.data.projects.length > 0) {
        setSelectedAudProject(response.data.projects[0]);
      }
    } catch (error) {
      console.error('Error fetching projects:', error);
    }
  };

  const fetchVideoProjects = async (UserEmail) => {
    try {
      const response = await axios.get(`${serving_url}/api/get-video-projects`, {
        params: { email: UserEmail }
      });

      if (response.data.projects && response.data.projects.length > 0) {
        setSelectedVidProject(response.data.projects[0]);
      }
    } catch (error) {
      console.error('Error fetching projects:', error);
    }
  };





  return (
    <AppContext.Provider
      value={{
        state: {
          apiLoading,
          isAuthenticated,
          selectedProject,
          userData,
          credits,
          setCredits,
          openLogin,
          setOpenLogin,
          setRemainingCredits,
          remainingCredits,
          selectedVidProject,
          selectedAudProject
        },
        setApiLoading,
        setIsAuthenticated,
        setSelectedProject,
        setSelectedVidProject,
        setSelectedAudProject,
        getUser
      }}
    >
      {!apiLoading && children}
    </AppContext.Provider>
  );
};

export default AppProvider;
