import React, { createContext, useMemo, useState, useContext, useEffect } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import themes from 'themes';
import { useDispatch, useSelector } from 'react-redux';
import * as actionTypes from 'store/actions';

export const ThemeContext = createContext();

const ThemeContextProvider = ({ children }) => {
    const customization = useSelector((state) => state.customization);
    const [isDarkMode, setIsDarkMode] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        // Set the initial theme based on local storage
        const savedTheme = localStorage.getItem('theme');
        if (savedTheme && savedTheme !== customization.navType) {
            dispatch({ type: actionTypes.TOGGLE_THEME });
        }
    }, [dispatch, customization.navType]);

    const themeOptions = useMemo(
        () =>
            themes({
                customization: customization 
            }),
        [customization]
    );

    return (
        <ThemeContext.Provider value={{ isDarkMode, setIsDarkMode }}>
           <ThemeProvider theme={themeOptions}>
                <CssBaseline />
                {children}
            </ThemeProvider>
        </ThemeContext.Provider>
    );
};

export default ThemeContextProvider;
