import { Box, Button, Card, TextField } from "@mui/material";
import React, { useState } from "react";


function ApplyCoupon({ onRedeem, processing }) {
    const [code, setCode] = useState();

    const handleChange = (event) => {
        const { value } = event.target;
        setCode(value);
    };



    return (
        <Box
            sx={{
                mt: 3,
            }}
        >

            <Card elevation={2}>
                <Box sx={{
                    padding: { xs: "2rem", lg: "1rem" },
                    // backgroundRepeat: 'no-repeat',
                    borderRadius: '0.75rem',
                    textAlign: 'center'
                }}>
                    <h1 style={{ color: "#9143E3", margin: 0 }}>Activate your deal</h1>
                    <p style={{ color: "#b2b2b2", marginTop: '10px' }}>If you have a life time deal coupon, you can add it here.</p>
                </Box>
            </Card>

            <Box
                sx={{
                    display: "flex",
                    gap: { xs: "20px", md: "30px", lg: "50px" },
                    flexDirection: { xs: "column", sm: "row" },
                    margin: "0 auto",
                    maxWidth: '800px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    mt: 5,
                }}
            >
                <TextField
                    placeholder="Enter coupon code here"
                    id="outlined-basic"
                    variant="standard"
                    value={code}
                    onChange={handleChange}
                    InputProps={{
                        disableUnderline: true,
                    }}
                    sx={{
                        width: "100%",
                        bgcolor: "#F3F8FF",
                        fontWeight: "600",
                        borderBottom: "none",
                        height: "100%",
                        padding: "8px 16px",
                        borderRadius: "10px 0px 10px 0px",
                        flex: { xs: 1, sm: 2, xl: 5 },
                    }}
                />
                <Button
                    disableElevation
                    variant="contained"
                    onClick={() => onRedeem(code)}
                    sx={{
                        width: "100%",
                        flex: 1,
                        bgcolor: "#b257f0",
                        fontWeight: 700,
                        fontSize: "16px",
                        "&:hover": { bgcolor: "#b257f0" },
                        padding: { xs: "8px 16px" },
                    }}
                    disabled={processing}
                >
                    Redeem
                </Button>
            </Box>
        </Box >
    );
}

export default ApplyCoupon;
