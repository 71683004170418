import SettingData from 'components/setting';
import MainCard from 'ui-component/cards/MainCard';


const Setting = () => (
    <MainCard title="Setting">
        <SettingData />
    </MainCard>
);

export default Setting;
