import React, { useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import ThemeRoutes from './routes'; // Adjusted import
import themes from 'themes';
import NavigationScroll from 'layout/NavigationScroll';
import AppProvider from 'context/AppProvider';

import { toggleTheme } from '../src/store/actions';
import ThemeContextProvider, { ThemeContext } from 'context/ThemeContext';

const App = () => {
    const customization = useSelector((state) => state.customization);
    // const { isDarkMode } = useSelector((state) => state.theme);

    // const { isDarkMode } = useSelector((state) => state.theme);
    // const dispatch = useDispatch();
    
    // const { setIsDarkMode } = useContext(ThemeContext);

    // // Toggle theme function
    // const handleToggleTheme = () => {
    //     dispatch(toggleTheme());
    //     setIsDarkMode(!isDarkMode);
    // };

    return (
        <StyledEngineProvider injectFirst>
            <ThemeContextProvider>
                <AppProvider>
                {/* <ThemeProvider theme={themes({ customization: { ...customization, navType: isDarkMode ? 'dark' : 'light' } })}> */}
                        <CssBaseline />
                        <NavigationScroll>
                            <ThemeRoutes />
                            {/* <button onClick={handleToggleTheme}>
                                Toggle Theme
                            </button> */}
                        </NavigationScroll>
                    {/* </ThemeProvider> */}
                </AppProvider>
            </ThemeContextProvider>
        </StyledEngineProvider>
    );
};

export default App;
