import { useState } from 'react';

// material-ui
import { useTheme, styled } from '@mui/material/styles';
import { Alert, Avatar, Box, Button, IconButton, Modal, Snackbar, Typography } from '@mui/material';
import ApplyCoupon from './ApplyCoupen';
import CloseIcon from '@mui/icons-material/Close';
import { jwtDecode } from 'jwt-decode';
import axios from 'axios';


const SearchSection = () => {
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [message, setMessage] = useState('');
    const [severity, setSeverity] = useState('');
    const [processing, setProcessing] = useState(false);

    const serving_url = process.env.REACT_APP_SERVICE_URL

    const SUCCESS_MSG = 'Credits redeemed!';
    const EMPTY_MSG = 'Please fill in the field!'
    const FAIL_MSG = 'Please enter the valid code!';

    const handleClose = () => {
        setOpen(false)
    }
    const upgradeAccountClose = () => setOpenModal(false);

    const handleRedeem = async (code) => {
        const token = localStorage.getItem('token');
        const decoded = jwtDecode(token);
        try {
            const response = await axios.post(`${serving_url}/api/redeem-code`, {
                code: code,
                email: decoded?.email
            });
            console.log(response, "response")
            if (response.status === 'error') {


            } else {
                if (response.data.Data === false) {
                    { code ? handleFailedRedeem() : handleEmptyRedeem() }
                } else {

                    handleSuccessfulRedeem();
                }
                setProcessing(false);
            }
        } catch (error) {
            handleEmptyRedeem()
        }
    };

    const handleSuccessfulRedeem = () => {
        setSeverity('success');
        setMessage(SUCCESS_MSG);
        setOpen(true);


        // setTimeout(() => {
        //     window.location.reload();
        // }, 2000);
    };

    const handleFailedRedeem = () => {
        setSeverity('error');
        setMessage(FAIL_MSG);
        setOpen(true);
    };

    const handleEmptyRedeem = () => {
        setSeverity('error');
        setMessage(EMPTY_MSG);
        setOpen(true);
    };



    return (
        <>
            <Box sx={{ marginLeft: { xs: '20px', md: '0px' } }}>
                <Button onClick={() => { setOpenModal(true) }} sx={{ background: 'linear-gradient(90deg, #3366FF 0%, #8E2DE2 100%)', color: '#fff' }}>Apply code</Button>
            </Box>

            <Modal
                open={openModal}
                onClose={upgradeAccountClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={{
                        padding: { xs: "20px", sm: "30px", md: "40px", lg: "50px" },
                        width: { xs: "90%", sm: "80%", md: "60%", lg: "40%" },
                        bgcolor: "background.paper",
                        boxShadow: 24,
                        borderRadius: 2,
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                    }}
                >
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={upgradeAccountClose}
                        sx={{ position: 'absolute', right: '20px', top: '20px' }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="body1" sx={{ mb: 2, textAlign: 'center', fontWeight: 'bold' }}>
                        Apply code
                    </Typography>
                    <Box
                        sx={{
                            padding: { xs: '20px', sm: '30px' },
                            borderRadius: 2,
                            boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
                            textAlign: 'center',
                        }}
                    >
                        <ApplyCoupon onRedeem={handleRedeem} processing={processing} />
                    </Box>
                    <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
                        <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
                            {message}
                        </Alert>
                    </Snackbar>
                </Box>
            </Modal>
        </>
    );
};

export default SearchSection;
