// assets
import { IconTypography, IconPalette, IconShadow, IconWindmill, IconSettings, IconMoneybag } from '@tabler/icons';

// constant
const icons = {
    IconTypography,
    IconPalette,
    IconShadow,
    IconWindmill,
    IconSettings,
    IconMoneybag
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const Pricing = {
    id: 'Setting',
    title: 'Setting',
    type: 'group',
    children: [
        {
            id: 'pricing',
            title: 'Pricing',
            type: 'item',
            url: '/pricing',
            icon: icons.IconMoneybag,
            breadcrumbs: false
        },
        {
            id: 'setting',
            title: 'Setting',
            type: 'item',
            url: '/setting',
            icon: icons.IconSettings,
            breadcrumbs: false
        }
    ]
};

export default Pricing;
