// assets
import { AudioOutlined, ProjectOutlined, FileImageOutlined, PicRightOutlined } from '@ant-design/icons';
import { IconScale, IconTexture } from '@tabler/icons';
import { IconKey } from '@tabler/icons';
// constant
const icons = {
  IconKey
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //


const pages = {
  id: 'pages',
  title: 'Image tools',
  type: 'group',
  children: [
    {
      id: 'text-to-img',
      title: 'Text-to-img',
      type: 'item',
      url: '/text-to-img',
      icon: FileImageOutlined,
    },
    {
      id: 'MidJounery-image',
      title: 'Ultra-HD-img',
      type: 'item',
      url: '/mid-jounery',
      icon: PicRightOutlined,
    },
    {
      id: 'upscale-image',
      title: 'Upscale-image',
      type: 'item',
      url: '/upscale-image',
      icon: IconScale,
    },
    {
      id: 'background-remover',
      title: 'Background remover',
      type: 'item',
      url: '/background-remover',
      icon: IconTexture,
    },
    {
      id: 'project',
      title: 'Image Project',
      type: 'item',
      url: '/project',
      icon: ProjectOutlined,
    },
    // {
    //   id: 'image-description',
    //   title: 'Image-to-text',
    //   type: 'item',
    //   url: '/image-to-description',
    //   icon: IconTexture,
    // },
    // {
    //   id: 'text-to-audio',
    //   title: 'Text-to-audio',
    //   type: 'item',
    //   url: '/text-to-audio',
    //   icon: AudioOutlined,
    // },
  ]
};


export default pages;
