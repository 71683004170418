import React, { useState, useEffect, useRef, useContext } from 'react';
import { Grid, Link, Typography, Button, IconButton, CircularProgress, Box } from '@mui/material';
import UploadIcon from '@mui/icons-material/Upload';
import SubCard from 'ui-component/cards/SubCard';
import MainCard from 'ui-component/cards/MainCard';
import { gridSpacing } from 'store/constant';
import axios from 'axios';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import AppContext from 'context/AppContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DownloadIcon from '@mui/icons-material/Download';

const serving_url = process.env.REACT_APP_SERVICE_URL;

const BackgroundRemover = () => {
    const [file, setFile] = useState(null);
    const [previewUrl, setPreviewUrl] = useState('');
    const [croppedUrl, setCroppedUrl] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [uploadUrl, setUploadUrl] = useState('');
    const [scaleImg, setScaleImg] = useState('');
    const cropperRef = useRef(null);
    const context = useContext(AppContext);
    const { state } = context;
    const { credits, setCredits, userData, remainingCredits, selectedProject } = state;


    useEffect(() => {
        if (file) {
            const objectUrl = URL.createObjectURL(file);
            setPreviewUrl(objectUrl);

            return () => URL.revokeObjectURL(objectUrl);
        } else {
            setPreviewUrl('');
        }
    }, [file]);

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            // Validate file type if necessary
            if (!selectedFile.type.startsWith('image/')) {
                setError('Please select a valid image file');
                setFile(null);
                return;
            }
            setFile(selectedFile);
            setError('');
        }
    };

    const handleCrop = () => {
        const cropper = cropperRef.current?.cropper;
        if (cropper) {
            setCroppedUrl(cropper.getCroppedCanvas().toDataURL())
        }
    };

    const uploadImgOns3 = (image) => {
        setUploadUrl('');
        fetch(image)
            .then(res => res.blob())
            .then((res) => {
                uploadFile(res)
            });
    }


    const uploadFile = async (croppedUrl) => {
        const file = croppedUrl;

        if (file) {
            setLoading(true);
            const SERVING_URL = process.env.REACT_APP_SERVICE_URL;
            const url = `${SERVING_URL}/api/uploading`;

            const formData = new FormData();
            formData.append("file", file);
            formData.append("fileType", file.type);

            fetch(url, {
                method: 'POST',
                body: formData
            })
                .then(res => res.json())
                .then(response => {
                    if (response.key) {
                        const src = `https://syraai1.s3.amazonaws.com/${response.key}`
                        removeBackground(src)
                    } else {
                        setError('Failed to get url');
                        setLoading(false)
                    }
                })
        }
    }

    const removeBackground = async (src) => {
        if (remainingCredits <= 0) {
            return false;
        }
        setScaleImg('');
        setLoading(true);
        setError('');

        try {
            const response = await axios.post(`${serving_url}/api/remove-background`, {
                src,
                uid: userData?.email,
                projectId: selectedProject._id
            });

            console.log(response, "response")
            if (response.data.data.status === 'success') {
                const imageUrl = response?.data?.data[0];
                await fetchWithRetry(imageUrl, 15, 1000);
                toast.success('Image successfully generated!');
            } else if (response.data.data.status === 'processing') {
                const id = response.data.data.id;
                fetchQueuedImages(id);
                toast.info('Image is being processed, please wait...');
            } else {
                setError('Failed to generate image');
                toast.error('Failed to generate image');
            }
        } catch (error) {
            setError('An error occurred while generating the image');
            toast.error('An error occurred while generating the image');
        }
    };


    const fetchQueuedImages = (id) => {
        setTimeout(() => checkImageStatus(id), 5000);
    };

    const checkImageStatus = async (id) => {
        try {
            const response = await axios.post(`${serving_url}/api/fetch-image`, {
                id,
                uid: userData?.email,
                projectId: selectedProject._id
            });
            console.log(response.data, "================response")

            if (response.data.data.Data.status === 'success') {
                const imageUrl = response?.data?.data.Data.output[0];
                await fetchWithRetry(imageUrl, 15, 1000);
            } else if (response.data.data.Data.status == 'processing') {
                const { id } = response.data.data.Data;
                const ids = response.data.data.id
                console.log(ids, "ids")
                fetchQueuedImages(id ?? ids);
            }
            else {
                setError('Failed to generate image');
            }
        } catch (error) {
            setError('An error occurred while generating the image');
        }
    };


    const fetchWithRetry = async (imageUrl, retries, delay) => {
        try {
            const imageResponse = await fetch(imageUrl);

            if (!imageResponse.ok && (imageResponse.status === 404 || imageResponse.status === 403)) {
                throw new Error('Image not found or forbidden');
            }
            setScaleImg(imageUrl);
            const creditsLeft = Math.max(0, credits + 1);
            setCredits(creditsLeft);
            setLoading(false);
        } catch (error) {
            if (retries > 0) {
                console.log(`Retrying... ${retries} attempts left`);
                setTimeout(() => fetchWithRetry(imageUrl, retries - 1, delay), delay);
            } else {
                setLoading(false);
                setError('Image could not be loaded after multiple attempts');
            }
        }
    };


    const handleDownload = (scaleImg) => {

        fetch(scaleImg)
            .then(response => response.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = 'syra.png';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            })
            .catch(() => console.error('Failed to download image.'));
    };




    return (
        <MainCard title="Remove image background">
            <Grid container spacing={gridSpacing}>
                <Grid item xs={12}>
                    <SubCard title="Upload Image">
                        <Grid container spacing={2} alignItems="center">
                            <Grid item>
                                <input
                                    accept="image/*"
                                    style={{ display: 'none' }}
                                    id="upload-file"
                                    type="file"
                                    onChange={handleFileChange}
                                />
                                <label htmlFor="upload-file">
                                    <IconButton color="primary" aria-label="upload picture" component="span">
                                        <UploadIcon />
                                    </IconButton>
                                </label>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => { uploadImgOns3(croppedUrl) }}
                                    disabled={loading || !croppedUrl || remainingCredits <= 0}
                                >
                                    {loading ? <CircularProgress size={24} /> : 'Generate'}
                                </Button>
                            </Grid>
                        </Grid>

                        {/* Image Preview and Cropper */}
                        <Grid item sx={{ display: 'flex', justifyContent: 'space-between', m: 4 }}>
                            {previewUrl && (
                                <Box sx={{ mt: 2 }}>
                                    <Typography variant="body1">Image Preview:</Typography>
                                    <Cropper
                                        src={previewUrl}
                                        style={{ height: 400, width: '50%' }}
                                        initialAspectRatio={1}
                                        guides={false}
                                        ref={cropperRef}
                                        crop={handleCrop}
                                    />
                                </Box>
                            )}

                            {/* Cropped Image Preview */}
                            {/* {scaleImg ? (
                                <Box sx={{ mt: 2, textAlign: 'center' }}>
                                    <Typography variant="subtitle1">Generated output:</Typography>
                                    <img src={scaleImg} alt='generated output' style={{ width: '400px' }} />
                                </Box>
                            ) : previewUrl ?
                                <Box sx={{ mt: '38px', display: 'flex', justifyContent: 'center', textAlign: 'center', border: '1px dashed #dfdfdf', width: '400px', height: '400px' }}>
                                    {loading && <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}><CircularProgress size={24} /></Box>}
                                </Box> : ''
                            } */}
                            {scaleImg ? (
                                <Box sx={{ mt: 2, textAlign: 'center', position: 'relative' }}>
                                    <img src={scaleImg} alt="generated output" style={{ width: '400px' }} />
                                    <IconButton
                                        color="primary"
                                        onClick={() => { handleDownload(scaleImg) }}
                                        sx={{
                                            position: 'absolute', top: '8px', right: '8px', backgroundColor: 'white',
                                            '&:hover': {
                                                backgroundColor: 'white'
                                            }
                                        }}
                                    >
                                        <DownloadIcon />
                                    </IconButton>
                                </Box>
                            ) : previewUrl ? (
                                <Box sx={{ mt: '38px', display: 'flex', justifyContent: 'center', textAlign: 'center', border: '1px dashed #dfdfdf', width: '400px', height: '400px' }}>
                                    {loading && (
                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <CircularProgress size={24} />
                                        </Box>
                                    )}
                                </Box>
                            ) : ''}
                        </Grid>

                        {/* Error Message */}
                        {error && (
                            <Typography color="error" variant="body2" sx={{ mt: 2 }}>
                                {error}
                            </Typography>
                        )}

                        {/* Success Message */}
                        {uploadUrl && (
                            <Typography variant="body2" sx={{ mt: 2 }}>
                                Image uploaded successfully.{' '}
                                <Link href={uploadUrl} target="_blank" rel="noopener">
                                    View Image
                                </Link>
                            </Typography>
                        )}
                    </SubCard>
                </Grid>
            </Grid>
            <ToastContainer />
        </MainCard>
    );
};

export default BackgroundRemover;
