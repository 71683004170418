import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid, Paper, TextField, Button, useTheme } from '@mui/material';
import { useContext } from "react";
import AppContext from "context/AppContext";
import axios from 'axios';

const SettingData = () => {
    const context = useContext(AppContext);
    const { state, getUser } = context;
    const { userData } = state;
    const theme = useTheme();
    const { mode } = theme.palette;

    // useEffect(() => {
    //     getUser()
    // }, [])

    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const serving_url = process.env.REACT_APP_SERVICE_URL

    const handlePasswordChange = async () => {
        if (newPassword !== confirmPassword) {
            alert("New passwords do not match!");
            return;
        }

        try {
            const response = await axios.post(`${serving_url}/api/auth/change-password`, {
                email: userData.email,
                oldPassword,
                newPassword
            });

            if (response.status === 200) {
                alert('Password changed successfully!');
            }
        } catch (error) {
            console.error('Error changing password:', error);
            alert('Error changing password. Please check your old password and try again.');
        }
    };


    return (
        <Box sx={{ p: 4 }}>
            <Typography variant="h4" gutterBottom sx={{ color: mode === 'light' ? '#000' : '#fff' }}>
                User Settings
            </Typography>
            <Paper elevation={3} sx={{ width: '60%', p: 3, mb: 4 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <Typography >Email:</Typography>
                        <Typography variant="body1">{userData.email}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography>Joining Date:</Typography>
                        <Typography variant="body1">{new Date(userData.create_at).toLocaleDateString()}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography>Plan:</Typography>
                        <Typography variant="body1">{userData.plan}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography>Total Credits:</Typography>
                        <Typography variant="body1">{userData.credits}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography>Used Credits:</Typography>
                        <Typography variant="body1">{userData.imageUsed}</Typography>
                    </Grid>
                </Grid>
            </Paper>

            <Typography variant="h5" gutterBottom sx={{ color: mode === 'light' ? '#000' : '#fff' }}>
                Change Password
            </Typography>
            <Paper elevation={3} sx={{ width: '60%', p: 3 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} >
                        <TextField
                            label="Old Password"
                            variant="outlined"
                            type="password"
                            fullWidth
                            value={oldPassword}
                            onChange={(e) => setOldPassword(e.target.value)}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.default : '#F8FAFC',
                                    '&.Mui-focused': {
                                        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.default : '#F8FAFC',
                                    },
                                    '& fieldset': {
                                        borderColor: theme.palette.divider,
                                    },
                                    '&:hover fieldset': {
                                        borderColor: theme.palette.primary.main,
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: theme.palette.primary.main,
                                    },
                                },
                                '& .MuiInputLabel-root': {
                                    color: theme.palette.text.primary,
                                },
                                '& .MuiInputBase-input': {
                                    color: theme.palette.text.primary,
                                    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.default : '#F8FAFC',
                                },
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="New Password"
                            variant="outlined"
                            type="password"
                            fullWidth
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.default : '#F8FAFC',
                                    '&.Mui-focused': {
                                        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.default : '#F8FAFC',
                                    },
                                    '& fieldset': {
                                        borderColor: theme.palette.divider,
                                    },
                                    '&:hover fieldset': {
                                        borderColor: theme.palette.primary.main,
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: theme.palette.primary.main,
                                    },
                                },
                                '& .MuiInputLabel-root': {
                                    color: theme.palette.text.primary,
                                },
                                '& .MuiInputBase-input': {
                                    color: theme.palette.text.primary,
                                    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.default : '#F8FAFC',
                                },
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Confirm New Password"
                            variant="outlined"
                            type="password"
                            fullWidth
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.default : '#F8FAFC',
                                    '&.Mui-focused': {
                                        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.default : '#F8FAFC',
                                    },
                                    '& fieldset': {
                                        borderColor: theme.palette.divider,
                                    },
                                    '&:hover fieldset': {
                                        borderColor: theme.palette.primary.main,
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: theme.palette.primary.main,
                                    },
                                },
                                '& .MuiInputLabel-root': {
                                    color: theme.palette.text.primary,
                                },
                                '& .MuiInputBase-input': {
                                    color: theme.palette.text.primary,
                                    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.default : '#F8FAFC',
                                },
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handlePasswordChange}
                        >
                            Change Password
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        </Box>
    );
}

export default SettingData;
