import { useState } from 'react';

// material-ui
import { useTheme, styled } from '@mui/material/styles';
import { Alert, Avatar, Box, Button, Card, IconButton, Modal, Snackbar, TextField, Typography } from '@mui/material';
import ApplyCoupon from './index';
import CloseIcon from '@mui/icons-material/Close';
import { jwtDecode } from 'jwt-decode';
import axios from 'axios';


const RedeemCode = () => {
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [message, setMessage] = useState('');
    const [severity, setSeverity] = useState('');
    const [processing, setProcessing] = useState(false);

    const serving_url = process.env.REACT_APP_SERVICE_URL

    const SUCCESS_MSG = 'Credits redeemed!';
    const EMPTY_MSG = 'Please fill in the field!'
    const FAIL_MSG = 'Please enter the valid code!';

    const handleClose = () => {
        setOpen(false)
    }
    const upgradeAccountClose = () => setOpenModal(false);

    const handleRedeem = async (code) => {
        const token = localStorage.getItem('token');
        const decoded = jwtDecode(token);
        try {
            const response = await axios.post(`${serving_url}/api/redeem-code`, {
                code: code,
                email: decoded?.email
            });
            console.log(response, "response")
            if (response.status === 'error') {


            } else {
                if (response.data.Data === false) {
                    { code ? handleFailedRedeem() : handleEmptyRedeem() }
                } else {

                    handleSuccessfulRedeem();
                }
                setProcessing(false);
            }
        } catch (error) {
            handleEmptyRedeem()
        }
    };

    const handleSuccessfulRedeem = () => {
        setSeverity('success');
        setMessage(SUCCESS_MSG);
        setOpen(true);


        setTimeout(() => {
            window.location.reload();
        }, 2000);
    };

    const handleFailedRedeem = () => {
        setSeverity('error');
        setMessage(FAIL_MSG);
        setOpen(true);
    };

    const handleEmptyRedeem = () => {
        setSeverity('error');
        setMessage(EMPTY_MSG);
        setOpen(true);
    };

    const [code, setCode] = useState();

    const handleChange = (event) => {
        const { value } = event.target;
        setCode(value);
    };




    return (
        <>
            <Box
                sx={{
                    padding: { xs: "20px", sm: "30px", md: "40px", lg: "50px" },
                    width: { xs: "90%", sm: "80%", md: "60%", lg: "40%" },
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    borderRadius: 2,
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                }}
            >
                <Typography variant="body1" sx={{ mb: 2, textAlign: 'center', fontWeight: 'bold' }}>
                    Redeem code
                </Typography>
                <Box
                    sx={{
                        padding: { xs: '20px', sm: '30px' },
                        borderRadius: 2,
                        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
                        textAlign: 'center',
                    }}
                >
                    <Box
                        sx={{
                            mt: 3,
                        }}
                    >

                        <Card elevation={2}>
                            <Box sx={{
                                padding: { xs: "2rem", lg: "1rem" },
                                // backgroundRepeat: 'no-repeat',
                                borderRadius: '0.75rem',
                                textAlign: 'center'
                            }}>
                                <h1 style={{ color: "#9143E3", margin: 0 }}>Activate your deal</h1>
                                <p style={{ color: "#b2b2b2", marginTop: '10px' }}>If you have a life time deal coupon, you can add it here.</p>
                            </Box>
                        </Card>

                        <Box
                            sx={{
                                display: "flex",
                                gap: { xs: "20px", md: "30px", lg: "50px" },
                                flexDirection: { xs: "column", sm: "row" },
                                margin: "0 auto",
                                maxWidth: '800px',
                                alignItems: 'center',
                                justifyContent: 'center',
                                mt: 5,
                            }}
                        >
                            <TextField
                                placeholder="Enter coupon code here"
                                id="outlined-basic"
                                variant="standard"
                                value={code}
                                onChange={handleChange}
                                InputProps={{
                                    disableUnderline: true,
                                }}
                                sx={{
                                    width: "100%",
                                    bgcolor: "#F3F8FF",
                                    fontWeight: "600",
                                    borderBottom: "none",
                                    height: "100%",
                                    padding: "8px 16px",
                                    borderRadius: "10px 0px 10px 0px",
                                    flex: { xs: 1, sm: 2, xl: 5 },
                                }}
                            />
                            <Button
                                disableElevation
                                variant="contained"
                                onClick={() => handleRedeem(code)}
                                sx={{
                                    width: "100%",
                                    flex: 1,
                                    bgcolor: "#b257f0",
                                    fontWeight: 700,
                                    fontSize: "16px",
                                    "&:hover": { bgcolor: "#b257f0" },
                                    padding: { xs: "8px 16px" },
                                }}
                                disabled={processing}
                            >
                                Redeem
                            </Button>
                        </Box>
                    </Box >
                    {/* <ApplyCoupon onRedeem={handleRedeem} processing={processing} /> */}
                </Box>
                <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
                        {message}
                    </Alert>
                </Snackbar>
            </Box>
        </>
    );
};

export default RedeemCode;
