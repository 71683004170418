import dashboard from './dashboard';
import pages from './pages';
import utilities from './utilities';
import Pricing from './Pricing';
import AudioProject from './audio';
import VideoRoute from './video';


const menuItems = {
    items: [dashboard, pages, AudioProject, VideoRoute, utilities, Pricing]
};

export default menuItems;
