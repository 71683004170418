import { useContext, useEffect, useState } from 'react';
import { Grid, TextField, Button, MenuItem, Typography, CircularProgress } from '@mui/material';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { Box } from '@mui/system';
import axios from 'axios';
import { useTheme } from '@mui/material/styles';
import { gridSpacing } from 'store/constant';
import DownloadImage from './DownloadImage';
import AppContext from 'context/AppContext';



const CreateVideo = () => {
    const theme = useTheme();
    const [prompt, setPrompt] = useState('');
    const [negativePrompt, setNegativePrompt] = useState('');
    const [images, setImages] = useState([]);

    const [generated, setGenerated] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const serving_url = process.env.REACT_APP_SERVICE_URL

    const context = useContext(AppContext);
    const { state } = context;
    const { selectedVidProject, credits, setCredits, remainingCredits, userData } = state;



    const handleGenerate = async () => {
        if (remainingCredits <= 0) {
            return false
        }

        setLoading(true);
        setError('');
        setImages([])
        setGenerated([])


        try {
            const response = await axios.post(`${serving_url}/api/text-to-img`, {
                prompt,
                uid: userData?.email,
                negative_prompt: negativePrompt,
                height: 512,
                samples: 4,
                projectId: selectedVidProject._id
            });

            const creditsLeft = Math.max(0, credits + 4);
            setCredits(creditsLeft);

            setGenerated(response?.data?.generate);
        } catch (error) {
            setError('Error generating images');
            console.error('Error generating images:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        generated?.output?.length && handleImagesLoaded(generated);
    }, [generated]);

    const handleImagesLoaded = (data) => {
        if (!Array.isArray(data?.output)) {
            validateImage(data?.output);
        } else {
            data?.output.forEach(img => validateImage(img));
        }
    };

    const validateImage = async (img, retries = 3) => {
        while (retries > 0) {
            try {
                const res = await fetch(img);

                if (res.status === 200) {
                    console.log(res.status, "res");
                    setImages(prevImages => [...prevImages, img]);
                    return;
                } else if (res.status === 404 || res.status === 403) {
                    retries -= 1;
                    if (retries > 0) {
                        console.log(`Retrying... (${retries} attempts left)`);
                        await new Promise(resolve => setTimeout(resolve, 1000));
                    } else {
                        console.log(`Failed to fetch image after multiple attempts: ${img}`);
                    }
                }
            } catch (err) {
                console.log('Error:', err);
                retries -= 1; // Decrement retry count on error
                if (retries > 0) {
                    console.log(`Retrying... (${retries} attempts left)`);
                    await new Promise(resolve => setTimeout(resolve, 1000));
                } else {
                    console.log(`Failed to fetch image after multiple attempts: ${img}`);
                }
            }
        }
    };




    return (
        <Grid container spacing={gridSpacing}>
            <Grid item xs={12} sm={4}>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
                    <TextField
                        fullWidth
                        label="Enter Prompt"
                        value={prompt}
                        onChange={(e) => setPrompt(e.target.value)}
                        margin="normal"
                        variant="outlined"
                        multiline
                        rows={12}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                backgroundColor: theme.palette.mode === 'dark' ? '#424242' : '#F8FAFC',
                                '&.Mui-focused': {
                                    backgroundColor: theme.palette.mode === 'dark' ? '#424242' : '#F8FAFC',
                                },
                                '& fieldset': {
                                    borderColor: theme.palette.divider,
                                },
                                '&:hover fieldset': {
                                    borderColor: theme.palette.primary.main,
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: theme.palette.primary.main,
                                },
                            },
                            '& .MuiInputLabel-root': {
                                color: theme.palette.text.primary,
                            },
                            '& .MuiInputBase-input': {
                                color: theme.palette.text.primary,
                                backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.default : '#F8FAFC',
                            },
                        }}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<AddPhotoAlternateIcon />}
                        onClick={handleGenerate}
                        fullWidth
                        sx={{ mt: 2 }}
                        disabled={loading || remainingCredits <= 0}
                    >
                        {loading ? 'Generating...' : 'Generate'}
                    </Button>
                    {error && (
                        <Typography variant="body2" color="error" sx={{ mt: 2 }}>
                            {error}
                        </Typography>
                    )}
                </Box>
            </Grid>
            <Grid item xs={12} sm={8}>
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    minHeight="400px"
                    border={`1px dashed ${theme.palette.divider}`}
                    sx={{ backgroundColor: theme.palette.background.default, color: theme.palette.text.primary }}
                >
                    {loading ? (
                        <CircularProgress />
                    ) : images?.length > 0 ? (
                        <Grid container spacing={2}>
                            {images?.map((src, index) => (
                                <Grid item xs={12} sm={6} key={index}>
                                    <DownloadImage imageUrl={src} altText={`Generated ${index}`} />
                                </Grid>
                            ))}
                        </Grid>
                    ) : (
                        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                            <Typography variant="h6" color="textSecondary" gutterBottom>
                                No Video generated yet
                            </Typography>
                        </Box>
                    )}
                </Box>
            </Grid>
        </Grid>
    );
};

export default CreateVideo;
