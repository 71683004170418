// assets
import { AudioOutlined, ProjectOutlined } from '@ant-design/icons';


const AudioRoute = {
    id: 'pages',
    title: 'Audio tools',
    type: 'group',
    children: [
        {
            id: 'text-to-audio',
            title: 'Text-to-audio',
            type: 'item',
            url: '/text-to-audio',
            icon: AudioOutlined,
        },
        {
            id: 'audio-project',
            title: 'Audio project',
            type: 'item',
            url: '/audio-project',
            icon: ProjectOutlined,
        },
    ]
};


export default AudioRoute;
