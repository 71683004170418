// assets
import { IconTypography, IconTexture, IconPalette, IconShadow, IconWindmill, IconStorm, IconRadioactive, IconHome } from '@tabler/icons';

// constant
const icons = {
    IconTypography,
    IconPalette,
    IconShadow,
    IconWindmill
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const ContentTool = {
    id: 'contanttool',
    title: 'Content-Tools',
    type: 'group',
    children: [
        {
            id: 'image-description',
            title: 'Image to text',
            type: 'item',
            url: '/image-to-description',
            icon: IconTexture,
        },
        {
            id: 'util-typography',
            title: 'Prompt-generator',
            type: 'item',
            url: '/prompt-generator',
            icon: IconStorm,
            breadcrumbs: false
        },
        {
            id: 'util-color',
            title: 'Story-generator',
            type: 'item',
            url: '/story-generator',
            icon: IconRadioactive,
            breadcrumbs: false
        }
    ]
};

export default ContentTool;
