import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Typography, Card, CardContent, CircularProgress, Box, IconButton } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';
import GraphicEqIcon from '@mui/icons-material/GraphicEq';
import CardMedia from '@mui/material/CardMedia';
import MainCard from 'ui-component/cards/MainCard';
import { useTheme } from '@mui/material/styles';
import styled from '@emotion/styled';

const ImageContainer = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 16px;
  margin-top: 24px;
`;

const ImageItem = styled(Card)`
  max-width: 345px;
  text-align: center;
  border-radius: ${({ theme }) => theme.shape.borderRadius};
  background-color: #512a76;
  border: 1px solid blueviolet;
  padding: 15px
`;

const VideoProjectDetailsPage = () => {
    const { id } = useParams();
    const [project, setProject] = useState(null);
    const [loading, setLoading] = useState(true);
    const [playingAudioId, setPlayingAudioId] = useState(null); // Track currently playing audio
    const audioRefs = useRef({}); // Store audio references
    const theme = useTheme(); // To access theme for styles
    const serving_url = process.env.REACT_APP_SERVICE_URL;

    useEffect(() => {
        const fetchProject = async () => {
            try {
                const response = await axios.get(`${serving_url}/api/video-project/${id}`);
                setProject(response.data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching project:', error);
                setLoading(false);
            }
        };

        fetchProject();
    }, [id]);

    const handlePlay = (audioId) => {
        const currentAudio = audioRefs.current[audioId];

        // If another audio is playing, stop it
        if (playingAudioId && playingAudioId !== audioId) {
            const previousAudio = audioRefs.current[playingAudioId];
            if (previousAudio) {
                previousAudio.pause();
                previousAudio.currentTime = 0; // Reset the previous audio
            }
        }

        // Play the selected audio
        currentAudio.play();
        setPlayingAudioId(audioId); // Track the playing audio
    };

    const handleStop = () => {
        if (playingAudioId) {
            const currentAudio = audioRefs.current[playingAudioId];
            if (currentAudio) {
                currentAudio.pause();
                currentAudio.currentTime = 0; // Reset current audio
            }
            setPlayingAudioId(null); // Reset playing audio state
        }
    };

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <CircularProgress />
            </Box>
        );
    }

    console.log(project, "project");

    return (
        <MainCard title="Project Details">
            {project ? (
                <Card>
                    <CardContent>
                        <Typography variant="h5" gutterBottom>{project.projectName}</Typography>
                        <Typography variant="body1" gutterBottom>{project.description}</Typography>

                        {/* Display audio files in a styled Card layout */}
                        {project.images && project.images.length > 0 && (
                            <ImageContainer>
                                {project.images.map((image) => (
                                    <ImageItem key={image._id} theme={theme}>
                                        {/* <CardMedia
                                            component="img"
                                            height="140"
                                            image="https://via.placeholder.com/300x140.png?text=Audio+Image" // Placeholder for image
                                            alt={image.audioName || 'Audio'}
                                        /> */}
                                        <CardContent>
                                            <Typography variant="h6" component="div" sx={{ color: '#fff' }}>
                                                {image.audioName || 'Audio File'}
                                            </Typography>
                                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 2 }}>
                                                <IconButton onClick={() => handlePlay(image._id)} color="primary">
                                                    <PlayArrowIcon />
                                                </IconButton>
                                                {playingAudioId === image._id && (
                                                    <GraphicEqIcon color="primary" sx={{ width: '100px', mx: 2 }} />
                                                )}
                                                <IconButton onClick={handleStop} color="secondary">
                                                    <StopIcon />
                                                </IconButton>
                                            </Box>
                                        </CardContent>
                                        <audio controls>
                                            <source src={image.audioUrl} type="audio/mpeg" />
                                            <track src="captions_en.vtt" kind="captions" srclang="en" label="English" />
                                            Your browser does not support the audio element.
                                        </audio>

                                    </ImageItem>
                                ))}
                            </ImageContainer>
                        )}
                    </CardContent>
                </Card>
            ) : (
                <Typography variant="body1">File not found</Typography>
            )}
        </MainCard>
    );
};

export default VideoProjectDetailsPage;
