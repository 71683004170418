// menu-item.js
import { ProjectOutlined } from '@ant-design/icons';
import { IconDashboard, IconChevronDown, IconPlus, IconHome } from '@tabler/icons';


const icons = { IconDashboard, IconChevronDown, IconPlus };

const dashboard = {
  id: 'dashboard',
  title: 'Dashboard',
  type: 'group',
  children: [
    {
      id: 'default',
      title: 'Dashboard',
      type: 'item',
      url: '/',
      icon: IconHome,
      breadcrumbs: false
    }
    // {
    //   id: 'team',
    //   title: 'Workspace',
    //   type: 'item',
    //   url: '/team',
    //   icon: icons.IconDashboard,
    // },

  ]
};

export default dashboard;
