
// components/DownloadImage.tsx
import React from 'react';
import { IconButton, Box } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const DownloadImage = ({ imageUrl, altText = 'Image' }) => {
  const handleDownload = () => {
    fetch(imageUrl)
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = altText;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      })
      .catch(() => console.error('Failed to download image.'));
  };

  return (
    <Box position="relative" display="inline-block" width="100%">
      <LazyLoadImage
        src={imageUrl}
        alt={altText}
        effect="blur"
        style={{ maxWidth: '100%', display: 'block' }}
      />
      <IconButton
        onClick={handleDownload}
        sx={{
          position: 'absolute',
          top: 8,
          right: 8,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          color: 'white',
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
          },
        }}
      >
        <DownloadIcon />
      </IconButton>
    </Box>
  );
};

export default DownloadImage;
