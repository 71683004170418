import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { Typography, Card, CardContent, Grid, CardActionArea, Box, Button, Modal, TextField, IconButton, Select, MenuItem, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import MainCard from 'ui-component/cards/MainCard';
import { useTheme } from '@mui/material/styles';
import AppContext from 'context/AppContext';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';


const AudioProject = () => {
    const [projects, setProjects] = useState([]);
    const [open, setOpen] = useState(false); // For modal
    const [newProjectName, setNewProjectName] = useState('');
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false); // For delete confirmation
    const [projectToDelete, setProjectToDelete] = useState(null); // Track which project to delete
    const navigate = useNavigate();
    const theme = useTheme();

    const context = useContext(AppContext);
    const { setSelectedAudProject, state } = context;
    const { userData, selectedAudProject } = state;
    const serving_url = process.env.REACT_APP_SERVICE_URL;

    useEffect(() => {
        const fetchProjects = async () => {
            try {
                const response = await axios.get(`${serving_url}/api/get-audio-projects`, {
                    params: { email: userData?.email }
                });
                setProjects(response.data.projects);
            } catch (error) {
                console.error('Error fetching projects:', error);
            }
        };

        fetchProjects();
    }, []);

    const handleProjectClick = (projectId) => {
        navigate(`/audio-project/${projectId}`);
    };

    const handleProjectSelect = (event) => {
        const selected = projects.find(project => project._id === event.target.value);
        setSelectedAudProject(selected);
    };

    const addNewProject = async () => {
        try {
            await axios.post(`${serving_url}/api/audio-projects`, {
                projectName: newProjectName,
                email: userData.email
            });
            setOpen(false); // Close modal after submission
            const response = await axios.get(`${serving_url}/api/get-audio-projects`, {
                params: { email: userData.email }
            });
            setProjects(response.data.projects);
            setNewProjectName('')
        } catch (error) {
            console.error('Error adding project:', error);
        }
    };

    const handleDeleteProject = async () => {
        try {
            await axios.delete(`${serving_url}/api/aud-projects/${projectToDelete._id}`);
            setProjects((prev) => prev.filter((project) => project._id !== projectToDelete._id));
            setDeleteDialogOpen(false);
        } catch (error) {
            console.error('Error deleting project:', error);
        }
    };

    const openDeleteDialog = (project) => {
        setProjectToDelete(project);
        setDeleteDialogOpen(true);
    };


    const cardStyles = {
        border: `1px solid ${theme.palette.divider}`,
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        borderRadius: '8px',
        transition: 'transform 0.2s',
        backgroundColor: theme.palette.background.paper,
        '&:hover': {
            transform: 'scale(1.05)'
        }
    };

    const textColor = theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.9)' : theme.palette.text.primary;

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '80vh',
                backgroundColor: theme.palette.background.default,
                padding: 3,
            }}
        >
            <MainCard title="Audio Project" sx={{ flexGrow: 1 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
                    <Typography variant="h4" gutterBottom sx={{ color: textColor }}>
                        Projects
                    </Typography>

                    <Box sx={{ display: 'flex', gap: '10px' }}>
                        <Button variant="contained" color="primary" sx={{ borderRadius: '10px' }} onClick={() => setOpen(true)}>
                            <AddCircleOutlineIcon />
                            <Typography sx={{ ml: 1 }}>New Project</Typography>
                        </Button>

                        {/* Select Project Dropdown */}
                        <Select
                            value={selectedAudProject?._id || ''}
                            onChange={handleProjectSelect}
                            displayEmpty
                            variant="outlined"
                            sx={{ minWidth: 200 }}
                        >
                            <MenuItem value="" disabled>
                                Select Project
                            </MenuItem>
                            {projects.map((project) => (
                                <MenuItem key={project._id} value={project._id}>
                                    {project.projectName}
                                </MenuItem>
                            ))}
                        </Select>
                    </Box>
                </Box>
                <Grid container spacing={3}>
                    {projects.map((project) => (
                        <Grid item xs={12} sm={6} md={4} key={project._id}>
                            <Card sx={cardStyles}>
                                {/* The project card click event */}
                                <CardActionArea onClick={() => handleProjectClick(project._id)}>
                                    <CardContent sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Typography variant="h6" component="div" sx={{ color: textColor }}>
                                            {project.projectName}
                                        </Typography>
                                        {/* Delete icon click with event stop propagation */}
                                        <IconButton
                                            sx={{ border: '1px solid #d76363', padding: '4px' }}
                                            color="error"
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                openDeleteDialog(project);
                                            }}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </MainCard>
            {/* Modal for new project */}
            <Modal open={open} onClose={() => setOpen(false)} aria-labelledby="new-project-modal">
                <Box sx={{ p: 3, backgroundColor: 'white', borderRadius: 1, maxWidth: 400, mx: 'auto', mt: 10 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography variant="h6">Add New Project</Typography>
                        <IconButton onClick={() => setOpen(false)}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <TextField
                        fullWidth
                        label="Project Name"
                        variant="outlined"
                        value={newProjectName}
                        onChange={(e) => setNewProjectName(e.target.value)}
                        sx={{ mt: 2 }}
                    />
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                        <Button onClick={() => setOpen(false)} sx={{ mr: 2 }}>Cancel</Button>
                        <Button variant="contained" color="primary" onClick={addNewProject}>Add</Button>
                    </Box>
                </Box>
            </Modal>

            {/* Delete Confirmation Dialog */}
            <Dialog
                open={deleteDialogOpen}
                onClose={() => setDeleteDialogOpen(false)}
                aria-labelledby="delete-project-dialog"
                sx={{ zIndex: 1300 }}
            >
                <DialogTitle>Delete Project</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete the project "{projectToDelete?.projectName}"? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
                    <Button onClick={handleDeleteProject} color="error">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default AudioProject;
