// assets
import { VideoCameraAddOutlined, ProjectOutlined } from '@ant-design/icons';


const VideoRoute = {
    id: 'pages',
    title: 'Video tools',
    type: 'group',
    children: [
        {
            id: 'text-to-video',
            title: 'Text-to-video',
            type: 'item',
            url: '/text-to-video',
            icon: VideoCameraAddOutlined,
        },
        {
            id: 'video-project',
            title: 'Video project',
            type: 'item',
            url: '/video-project',
            icon: ProjectOutlined,
        },
    ]
};


export default VideoRoute;
