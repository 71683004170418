import React, { useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import AppContext from '../context/AppContext';

const PrivateRoute = ({ element: Component, ...rest }) => {
  const { state } = useContext(AppContext);
  const { isAuthenticated, apiLoading } = state;
  const location = useLocation();

  if (apiLoading) {
    // Optionally, you can render a loading spinner or some placeholder content here.
    return <div>Loading...</div>;
  }

  return isAuthenticated ? (
    Component
  ) : (
    <Navigate to="/landing-page" state={{ from: location }} />
  );
};

export default PrivateRoute;
