const themePalette = (theme) => {
    const mode = theme.customization.navType;

    return {
        mode,
        common: {
            black: theme.colors?.darkPaper || '#000',
            white: theme.colors?.white || '#fff'
        },
        primary: {
            light: theme.colors?.primaryLight || '#7986cb',
            main: theme.colors?.primaryMain || '#3f51b5',
            dark: theme.colors?.primaryDark || '#303f9f',
            contrastText: theme.colors?.white || '#fff'
        },
        secondary: {
            light: theme.colors?.secondaryLight || '#ff4081',
            main: theme.colors?.secondaryMain || '#f50057',
            dark: theme.colors?.secondaryDark || '#c51162',
            contrastText: theme.colors?.white || '#fff'
        },
        error: {
            light: theme.colors?.errorLight || '#e57373',
            main: theme.colors?.errorMain || '#f44336',
            dark: theme.colors?.errorDark || '#d32f2f',
            contrastText: theme.colors?.white || '#fff'
        },
        warning: {
            light: theme.colors?.warningLight || '#ffb74d',
            main: theme.colors?.warningMain || '#ff9800',
            dark: theme.colors?.warningDark || '#f57c00',
            contrastText: theme.colors?.white || '#fff'
        },
        info: {
            light: theme.colors?.infoLight || '#64b5f6',
            main: theme.colors?.infoMain || '#2196f3',
            dark: theme.colors?.infoDark || '#1976d2',
            contrastText: theme.colors?.white || '#fff'
        },
        success: {
            light: theme.colors?.successLight || '#81c784',
            main: theme.colors?.successMain || '#4caf50',
            dark: theme.colors?.successDark || '#388e3c',
            contrastText: theme.colors?.white || '#fff'
        },
        grey: {
            50: theme.colors?.grey50 || '#fafafa',
            100: theme.colors?.grey100 || '#f5f5f5',
            200: theme.colors?.grey200 || '#eeeeee',
            300: theme.colors?.grey300 || '#e0e0e0',
            400: theme.colors?.grey400 || '#bdbdbd',
            500: theme.colors?.grey500 || '#9e9e9e',
            600: theme.colors?.grey600 || '#757575',
            700: theme.colors?.grey700 || '#616161',
            800: theme.colors?.grey800 || '#424242',
            900: theme.colors?.grey900 || '#212121',
            A100: theme.colors?.greyA100 || '#d5d5d5',
            A200: theme.colors?.greyA200 || '#aaaaaa',
            A400: theme.colors?.greyA400 || '#303030',
            A700: theme.colors?.greyA700 || '#616161'
        },
        background: {
            paper: mode === 'dark' ? theme.colors?.darkLevel2 || '#424242' : theme.colors?.paper || '#fff',
            default: mode === 'dark' ? theme.colors?.darkBackground || '#303030' : theme.colors?.background || '#fafafa'
        },
        text: {
            primary: mode === 'dark' ? theme.colors?.grey50 || '#fafafa' : theme.colors?.grey900 || '#212121',
            secondary: mode === 'dark' ? theme.colors?.grey200 || '#eeeeee' : theme.colors?.grey700 || '#616161',
            disabled: mode === 'dark' ? theme.colors?.grey400 || '#bdbdbd' : theme.colors?.grey500 || '#9e9e9e',
            hint: mode === 'dark' ? theme.colors?.grey300 || '#e0e0e0' : theme.colors?.grey400 || '#bdbdbd'
        },
        divider: theme.colors?.grey200 || '#e0e0e0'
    };
};

export default themePalette;
