import { useContext } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Avatar,
    Box,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Switch,
    Typography,
    useMediaQuery
} from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';
import { ThemeContext } from 'context/ThemeContext';
import { toggleTheme } from 'store/actions';


// ==============================|| NOTIFICATION ||============================== //

const NotificationSection = () => {
    const theme = useTheme();
    const matchesXs = useMediaQuery(theme.breakpoints.down('md'));
    const { isDarkMode, setIsDarkMode } = useContext(ThemeContext);
    const dispatch = useDispatch();

    const customization = useSelector((state) => state.customization);

    const handleToggleTheme = () => {
        dispatch(toggleTheme());
        setIsDarkMode(!isDarkMode);
    };

    const textColor = theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.7)' : theme.palette.text.primary;
    const iconColor = theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.7)' : theme.palette.text.primary;


    return (
        <>
            <Box
                sx={{
                    ml: 1,
                    mr: 3,
                    [theme.breakpoints.down('md')]: {
                        mr: 2
                    }
                }}
            >
                <ListItemButton
                    sx={{ borderRadius: `${customization.borderRadius}px` }}
                    onClick={handleToggleTheme}
                >
                    <ListItemText primary={<Typography variant="body2" sx={{ color: textColor }}>Dark Mode</Typography>} />
                    <ListItemIcon sx={{ color: iconColor }}>
                        <Switch checked={isDarkMode} />
                    </ListItemIcon>
                </ListItemButton>
            </Box>
        </>
    );
};

export default NotificationSection;
