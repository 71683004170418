import React from 'react';

import MainCard from 'ui-component/cards/MainCard';
import CreateVideo from 'components/CreateVideo';



const TextToVideo = () => {

    return (
        <MainCard title="Text to video">
            <CreateVideo />
        </MainCard>
    );
};

export default TextToVideo;
